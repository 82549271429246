<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/store/auth';

const { operator, authenticated, organizations, shops } = storeToRefs(useAuthStore());
const operatorRole = operator.value?.role;

const { logout } = useAuthStore();

const data = ref({
  title: 'Merci Admin',
  drawer: true,
  loading: false,
});

const currentShopId = ref(operator.value?.current_shop_id);
const currentOrganizationId = ref(operator.value?.current_organization_id);

const sidebarItems = ref([
  {
    header: 'レポート',
    displayable: true,
  },
  {
    icon: 'mdi-view-dashboard',
    title: '全体',
    to: '/dashboard',
    displayable: operatorRole === 'system_manager' || operatorRole === 'organization_manager',
    color: 'red',
  },
  {
    icon: 'mdi-circle-multiple-outline',
    title: 'ポイント',
    to: '/report-points',
    displayable: operatorRole === 'system_manager' || operatorRole === 'organization_manager',
    color: 'red',
  },
  {
    icon: 'mdi-calendar-badge',
    title: '予約分析',
    to: '/report-reservations',
    displayable: true,
    color: 'red',
  },
  {
    icon: 'mdi-receipt-text',
    title: '会計分析',
    to: '/report-receipts',
    displayable: true,
    color: 'red',
  },
  {
    icon: 'mdi-select-group',
    title: 'RFM分析',
    to: '/report-rfm',
    displayable: true,
    color: 'red',
  },
  {
    icon: 'mdi-mail',
    title: 'キャンペーン分析',
    to: '/campaign-reports',
    displayable: true,
    color: 'red',
  },
  {
    icon: 'mdi-arrow-decision-outline',
    title: 'アクセス分析',
    displayable: true,
    color: 'red',
    lists: [
      {
        title: 'ページビュー',
        to: '/statistics/page-views',
        displayable: true,
      },
      {
        title: '店舗予約',
        to: '/statistics/reservation-views',
        displayable: true,
      },
    ],
  },
  {
    header: '組織管理',
    displayable: true,
  },
  {
    icon: 'mdi-domain',
    title: '組織設定',
    to: '/organizations',
    displayable: operatorRole === 'system_manager',
  },
  {
    icon: 'mdi-storefront',
    title: '店舗一覧',
    to: '/shops',
    displayable: true,
  },
  {
    icon: 'mdi-account-tie',
    title: '管理者アカウント',
    to: '/operators',
    displayable: operatorRole === 'system_manager' || operatorRole === 'organization_manager',
  },
  {
    icon: 'mdi-account-group',
    title: '会員管理',
    to: '/users',
    displayable: true,
  },
  {
    icon: 'mdi-account-multiple',
    title: 'セグメント',
    to: '/user-segments',
    displayable: true,
  },
  {
    icon: 'mdi-bullhorn',
    title: 'キャンペーン',
    to: '/campaigns',
    displayable: true,
  },
  {
    icon: 'mdi-ticket-percent',
    title: 'クーポン',
    to: '/coupons',
    displayable: true,
  },
  {
    icon: 'mdi-bell',
    title: 'お知らせ',
    to: '/news',
    displayable: true,
  },
  {
    icon: 'mdi-account-star',
    title: 'ランキング',
    to: '/rank-settings',
    displayable: operatorRole === 'system_manager' || operatorRole === 'organization_manager',
  },
  {
    header: '店舗管理',
    displayable: true,
  },
  {
    icon: 'mdi-calendar-badge',
    title: '予約',
    to: '/reservations',
    displayable: true,
  },
  {
    icon: 'mdi-food',
    title: 'メニュー',
    to: '/menu-items',
    displayable: true,
  },
  {
    icon: 'mdi-nutrition',
    title: 'ジャンル',
    to: '/genres',
    displayable: true,
  },
]);

watch(currentShopId, async value => {
  if (value) {
    await $api.switchShop(value || 0);
    reloadNuxtApp({ ttl: 0 });
  }
});

watch(currentOrganizationId, async value => {
  if (value) {
    await $api.switchOrganization(value || 0);
    reloadNuxtApp({ ttl: 0 });
  }
});

watch(operator, async value => {
  if (value) {
    currentShopId.value = value.current_shop_id;
    currentOrganizationId.value = value.current_organization_id;
  }
});
</script>

<template>
  <admin-sidebar
    v-model:drawer="data.drawer"
    :items="sidebarItems"
  >
    <template #logo>
      <nuxt-link
        to="/"
        exact
        class="text-decoration-none"
      >
        <h4 class="text-h4 text-primary">MFP管理画面</h4>
      </nuxt-link>
    </template>
  </admin-sidebar>

  <v-app-bar
    class="m-app-bar bg-white px-5"
    height="70"
  >
    <v-spacer />

    <template v-slot:append>
      <m-select
        v-if="operator?.role === 'system_manager'"
        v-model="currentOrganizationId"
        :items="organizations"
        item-title="name"
        item-value="id"
        :inlineLabel="$t('general.organization')"
        class="header-selection mr-2"
      />

      <m-select
        v-model="currentShopId"
        :items="shops"
        item-title="name"
        item-value="id"
        :inlineLabel="$t('general.restaurant')"
        class="header-selection mr-4"
      />

      <v-menu
        offset-y
        left
      >
        <template #activator="{ props }">
          <div
            v-bind="props"
            class="d-flex align-center cursor-pointer"
          >
            <v-avatar
              size="48"
              color="primary"
            >
              <v-icon icon="mdi-account" />
            </v-avatar>

            <div class="d-inline-flex flex-column justify-center ml-2">
              <span class="text-subtitle-1 font-weight-semibold"> {{ operator?.name }} </span>
              <small class="text-medium-emphasis">
                {{ $t(`enums.roles.${operator?.role}`) }}
              </small>
            </div>
          </div>
        </template>

        <v-list>
          <v-list-item
            link
            prepend-icon="mdi-logout-variant"
            @click="logout()"
            title="ログアウト"
          >
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-app-bar>
  <merci-confirm ref="confirm" />
</template>

<style lang="scss" scoped>
.header-selection {
  width: 250px;
}

:deep(.v-toolbar__append) {
  margin-inline-end: 0;
}

.m-app-bar {
  box-shadow: 0px 2px 6px 0px #2553b91a !important;
}
</style>
