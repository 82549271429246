export default defineNuxtPlugin(() => {
  return {
    provide: {
      rules: {
        required: (v: any) => (v !== null && v !== undefined && v !== '') || '必須項目です',
        max: (max: number, v: any) => {
          return v <= max || `${max} より小さい値を設定してください`;
        },
        min: (min: number, v: any) => {
          return v >= min || `${min} より大きい値を設定してください`;
        },
        maxTime: (max: string, v: any) => {
          return v <= max || `${max} より前の時間を設定してください`;
        },
        email: (v: any) => /.+@.+\..+/.test(v) || 'メールアドレスを入力してください',
        password: (v: any) => /.{8,}/.test(v) || 'パスワードは8文字以上で入力してください',
        maxLength: (max: number, v: any) => v.length <= max || '文字以内で入力してください',
        confirmPassword: (v1: string, v2: string) => v1 === v2 || 'パスワードが一致しません',
      },
    },
  };
});
