<script lang="ts" setup>
const model = defineModel<any>();

withDefaults(
  defineProps<{
    label?: string;
    inlineLabel?: string;
    rules?: any[];
    placeholder?: string;
    type?: string;
    hideDetails?: boolean | 'auto';
    width?: string | number;
    prependInnerIcon?: string;
    requiredMark?: boolean;
    min?: number;
    max?: number;
    disabled?: boolean;
  }>(),
  {
    hideDetails: 'auto',
  },
);
</script>

<template>
  <div :style="{ width }">
    <m-input-label
      :text="label"
      :required-mark="requiredMark"
    />

    <v-text-field
      v-model="model"
      :rules="rules"
      variant="outlined"
      dense
      validate-on="blur"
      :type="type"
      :min="min"
      :max="max"
      :placeholder="placeholder"
      :hide-details="hideDetails"
      :label="inlineLabel"
      :width="width"
      :prepend-inner-icon="prependInnerIcon"
      :disabled="disabled"
    />
  </div>
</template>
