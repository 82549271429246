<script lang="ts" setup>
import { useConfirmDialogStore } from '~/store/confirmDialog';
const confirmDialogStore = useConfirmDialogStore();
</script>

<template>
  <m-dialog
    v-model="confirmDialogStore.dialog"
    :max-width="confirmDialogStore.option.width"
    @keydown.esc="confirmDialogStore.cancel"
  >
    <template #title>
      {{ confirmDialogStore.title }}
    </template>

    {{ confirmDialogStore.message }}

    <template #actions>
      <m-btn
        variant="tonal"
        @click="confirmDialogStore.cancel"
      >
        {{ $t('general.cancel') }}
      </m-btn>

      <m-btn
        :color="confirmDialogStore.option.confirmColor"
        @click="confirmDialogStore.agree"
      >
        {{ confirmDialogStore.option.confirmText }}
      </m-btn>
    </template>
  </m-dialog>
</template>

<style scoped>
div {
  word-break: break-word;
}
</style>
