<script lang="ts" setup>
const dialog = defineModel<boolean>();

withDefaults(
  defineProps<{
    maxWidth?: string | number;
  }>(),
  {
    maxWidth: 500,
  },
);
</script>

<template>
  <v-dialog
    v-model="dialog"
    :max-width="maxWidth"
  >
    <div class="m-card m-dialog">
      <div class="m-dialog__title text-h6 font-weight-semibold pt-2 pb-4">
        <slot name="title" />
      </div>

      <div class="text-body-1 py-4">
        <slot />
      </div>

      <div class="m-dialog__actions d-flex align-center justify-end">
        <slot name="actions" />
      </div>
    </div>
  </v-dialog>
</template>

<style lang="scss" scoped>
.m-dialog {
  border-radius: 10px;

  &__title {
    border-bottom: 1px solid #ededed;
  }

  &__actions {
    gap: 8px;
  }
}
</style>
