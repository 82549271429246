<script setup lang="ts">
import { useSnackbarStore } from '~/store/snackbar';
const snackbarStore = useSnackbarStore();

const switchLocalePath = useSwitchLocalePath();
</script>

<template>
  <div>
    <VApp theme="light">
      <VMain class="admin-layout">
        <v-snackbar
          v-model="snackbarStore.active"
          :color="snackbarStore.color"
          location="top center"
          @on-vnode-unmounted="snackbarStore.active = false"
        >
          {{ snackbarStore.content }}
        </v-snackbar>

        <div class="h-100">
          <slot />
        </div>
      </VMain>

      <v-footer
        app
        class="admin-footer"
      >
        <span>&copy; {{ new Date().getFullYear() }}</span>
        <v-spacer />
        <!-- <div class="mx-1">
          <v-btn @click="switchLocalePath($i18n.locale === 'ja' ? 'en' : 'ja')">
            <v-icon>mdi-web</v-icon>
            {{ $i18n.locale === 'ja' ? 'EN' : 'JA' }}
          </v-btn>
        </div> -->
      </v-footer>
    </VApp>
  </div>
</template>
