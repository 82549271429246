import { defineStore } from 'pinia';

export interface Option {
  confirmColor: string;
  confirmText: string;
  width: number;
}

type Resolve = (value: boolean) => void;
type Reject = (value: boolean) => void;

const defaultOption: Option = {
  confirmColor: 'primary',
  confirmText: 'OK',
  width: 450,
};

export const useConfirmDialogStore = defineStore('confirmDialog', {
  state: () => ({
    dialog: false,
    title: '',
    message: '',
    resolve: <Resolve>{},
    reject: <Reject>{},
    option: defaultOption,
  }),

  actions: {
    open(title: string, message: string, option?: Partial<Option>): Promise<boolean> {
      if (option) {
        this.option = { ...defaultOption, ...option };
      } else {
        this.option = defaultOption;
      }

      this.title = title;
      this.message = message;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    agree() {
      this.resolve(true);
      this.dialog = false;
    },

    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
});
