<template>
  <div class="h-100 d-flex justify-center align-center bg-dark-20">
    <div class="pre-login-card bg-background rounded">
      <h5 class="text-center text-h5 mb-9"><slot name="title"></slot></h5>

      <p
        v-if="!!$slots['sub-title']"
        class="text-center text-dark-500 mb-9"
      >
        <slot name="sub-title"></slot>
      </p>

      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pre-login-card {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  padding: 48px;
  width: 480px;
}
</style>
