<script lang="ts" setup>
const model = defineModel<any>();

withDefaults(
  defineProps<{
    label?: string;
    inlineLabel?: string;
    items?: any[];
    itemTitle?: string;
    itemValue?: string;
    rules?: any[];
    placeholder?: string;
    requiredMark?: boolean;
    type?: string;
    hideDetails?: boolean | 'auto';
    multiple?: boolean;
    chips?: boolean;
    disabled?: boolean;
    closableChips?: boolean;
    width?: string;
    clearable?: boolean;
  }>(),
  {
    hideDetails: 'auto',
  },
);
</script>

<template>
  <div>
    <m-input-label
      :text="label"
      :required-mark="requiredMark"
    />

    <v-select
      v-model="model"
      :rules="rules"
      variant="outlined"
      dense
      validate-on="blur"
      :type="type"
      :placeholder="placeholder"
      :hide-details="hideDetails"
      :label="inlineLabel"
      :items="items"
      :item-title="itemTitle"
      :item-value="itemValue"
      :multiple="multiple"
      :disabled="disabled"
      :chips="chips"
      :closable-chips="closableChips"
      :width="width"
      :clearable="clearable"
    />
  </div>
</template>
