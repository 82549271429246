
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexI9nCepyvOxMeta } from "/html/pages/campaign-reports/index.vue?macro=true";
import { default as _91id_93347TRcNxNDMeta } from "/html/pages/campaigns/[id].vue?macro=true";
import { default as indexe8i59w8nEuMeta } from "/html/pages/campaigns/index.vue?macro=true";
import { default as newC9TBwvyvS8Meta } from "/html/pages/campaigns/new.vue?macro=true";
import { default as _91id_93B4pQKCBST1Meta } from "/html/pages/coupons/[id].vue?macro=true";
import { default as indexyvlze5Gd1pMeta } from "/html/pages/coupons/index.vue?macro=true";
import { default as newTJ8wyWrUQ7Meta } from "/html/pages/coupons/new.vue?macro=true";
import { default as indexFfB84yCksOMeta } from "/html/pages/dashboard/index.vue?macro=true";
import { default as _91id_931twfuEjajHMeta } from "/html/pages/genres/[id].vue?macro=true";
import { default as indexB8qYu2BjjQMeta } from "/html/pages/genres/index.vue?macro=true";
import { default as newTxqaSBVxjyMeta } from "/html/pages/genres/new.vue?macro=true";
import { default as indexIh7resPdAkMeta } from "/html/pages/index.vue?macro=true";
import { default as _91id_93ho7yUeI7eaMeta } from "/html/pages/menu-items/[id].vue?macro=true";
import { default as index3zM3qcRgWbMeta } from "/html/pages/menu-items/index.vue?macro=true";
import { default as newm73ijZEAJlMeta } from "/html/pages/menu-items/new.vue?macro=true";
import { default as _91id_93ZzuNMeRHUsMeta } from "/html/pages/news/[id].vue?macro=true";
import { default as indexDqSBhlpUz9Meta } from "/html/pages/news/index.vue?macro=true";
import { default as newNeQoDEIgjLMeta } from "/html/pages/news/new.vue?macro=true";
import { default as _91id_936ZZpzQhxfxMeta } from "/html/pages/operators/[id].vue?macro=true";
import { default as indexMcs0QV33daMeta } from "/html/pages/operators/index.vue?macro=true";
import { default as new5EMiRjFrAHMeta } from "/html/pages/operators/new.vue?macro=true";
import { default as _91id_93VP7gQNSKbMMeta } from "/html/pages/organizations/[id].vue?macro=true";
import { default as indexU7N9V9MX6nMeta } from "/html/pages/organizations/index.vue?macro=true";
import { default as indexsg10wg2y74Meta } from "/html/pages/password-reset/index.vue?macro=true";
import { default as new_45passwordxdqC5Bel35Meta } from "/html/pages/password-reset/new-password.vue?macro=true";
import { default as sentgRPtWvLRA4Meta } from "/html/pages/password-reset/sent.vue?macro=true";
import { default as successqH7Q5P3OfsMeta } from "/html/pages/password-reset/success.vue?macro=true";
import { default as _91id_93OEWjCPWkFSMeta } from "/html/pages/rank-settings/benefits/[id].vue?macro=true";
import { default as new7WfF1OSycGMeta } from "/html/pages/rank-settings/benefits/new.vue?macro=true";
import { default as indexaFLY1tlA56Meta } from "/html/pages/rank-settings/index.vue?macro=true";
import { default as _91id_93X6OwJp2fcBMeta } from "/html/pages/rank-settings/ranks/[id].vue?macro=true";
import { default as new60JwiJLsDAMeta } from "/html/pages/rank-settings/ranks/new.vue?macro=true";
import { default as indexFptdu5oDqOMeta } from "/html/pages/report-points/index.vue?macro=true";
import { default as indexxypiisMAZ7Meta } from "/html/pages/report-receipts/index.vue?macro=true";
import { default as indextIGue7Uuv1Meta } from "/html/pages/report-reservations/index.vue?macro=true";
import { default as index2PWO7oaeAJMeta } from "/html/pages/report-rfm/index.vue?macro=true";
import { default as _91id_936Cj6kMSA3OMeta } from "/html/pages/reservations/[id].vue?macro=true";
import { default as indexqVlNE7E0nGMeta } from "/html/pages/reservations/index.vue?macro=true";
import { default as _91id_93qzMqLCrZiTMeta } from "/html/pages/shops/[id].vue?macro=true";
import { default as indexHQJnko0xoXMeta } from "/html/pages/shops/index.vue?macro=true";
import { default as newOh6HuTgvhzMeta } from "/html/pages/shops/new.vue?macro=true";
import { default as signinZLX4IYXrMZMeta } from "/html/pages/signin.vue?macro=true";
import { default as page_45views4SIm3te3jzMeta } from "/html/pages/statistics/page-views.vue?macro=true";
import { default as reservation_45viewsfttdUtrVZ0Meta } from "/html/pages/statistics/reservation-views.vue?macro=true";
import { default as _91id_931KOy5qdgFJMeta } from "/html/pages/user-segments/[id].vue?macro=true";
import { default as indexMhvLUOhSIZMeta } from "/html/pages/user-segments/index.vue?macro=true";
import { default as newJacvNIYou5Meta } from "/html/pages/user-segments/new.vue?macro=true";
import { default as _91id_93Jj3ctdVTBOMeta } from "/html/pages/users/[id].vue?macro=true";
import { default as indexKU1wLHtDKfMeta } from "/html/pages/users/index.vue?macro=true";
export default [
  {
    name: "campaign-reports",
    path: "/campaign-reports",
    meta: indexI9nCepyvOxMeta || {},
    component: () => import("/html/pages/campaign-reports/index.vue")
  },
  {
    name: "campaigns-id",
    path: "/campaigns/:id()",
    component: () => import("/html/pages/campaigns/[id].vue")
  },
  {
    name: "campaigns",
    path: "/campaigns",
    component: () => import("/html/pages/campaigns/index.vue")
  },
  {
    name: "campaigns-new",
    path: "/campaigns/new",
    component: () => import("/html/pages/campaigns/new.vue")
  },
  {
    name: "coupons-id",
    path: "/coupons/:id()",
    meta: _91id_93B4pQKCBST1Meta || {},
    component: () => import("/html/pages/coupons/[id].vue")
  },
  {
    name: "coupons",
    path: "/coupons",
    meta: indexyvlze5Gd1pMeta || {},
    component: () => import("/html/pages/coupons/index.vue")
  },
  {
    name: "coupons-new",
    path: "/coupons/new",
    meta: newTJ8wyWrUQ7Meta || {},
    component: () => import("/html/pages/coupons/new.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexFfB84yCksOMeta || {},
    component: () => import("/html/pages/dashboard/index.vue")
  },
  {
    name: "genres-id",
    path: "/genres/:id()",
    component: () => import("/html/pages/genres/[id].vue")
  },
  {
    name: "genres",
    path: "/genres",
    component: () => import("/html/pages/genres/index.vue")
  },
  {
    name: "genres-new",
    path: "/genres/new",
    component: () => import("/html/pages/genres/new.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/html/pages/index.vue")
  },
  {
    name: "menu-items-id",
    path: "/menu-items/:id()",
    component: () => import("/html/pages/menu-items/[id].vue")
  },
  {
    name: "menu-items",
    path: "/menu-items",
    component: () => import("/html/pages/menu-items/index.vue")
  },
  {
    name: "menu-items-new",
    path: "/menu-items/new",
    component: () => import("/html/pages/menu-items/new.vue")
  },
  {
    name: "news-id",
    path: "/news/:id()",
    meta: _91id_93ZzuNMeRHUsMeta || {},
    component: () => import("/html/pages/news/[id].vue")
  },
  {
    name: "news",
    path: "/news",
    meta: indexDqSBhlpUz9Meta || {},
    component: () => import("/html/pages/news/index.vue")
  },
  {
    name: "news-new",
    path: "/news/new",
    meta: newNeQoDEIgjLMeta || {},
    component: () => import("/html/pages/news/new.vue")
  },
  {
    name: "operators-id",
    path: "/operators/:id()",
    meta: _91id_936ZZpzQhxfxMeta || {},
    component: () => import("/html/pages/operators/[id].vue")
  },
  {
    name: "operators",
    path: "/operators",
    meta: indexMcs0QV33daMeta || {},
    component: () => import("/html/pages/operators/index.vue")
  },
  {
    name: "operators-new",
    path: "/operators/new",
    meta: new5EMiRjFrAHMeta || {},
    component: () => import("/html/pages/operators/new.vue")
  },
  {
    name: "organizations-id",
    path: "/organizations/:id()",
    meta: _91id_93VP7gQNSKbMMeta || {},
    component: () => import("/html/pages/organizations/[id].vue")
  },
  {
    name: "organizations",
    path: "/organizations",
    meta: indexU7N9V9MX6nMeta || {},
    component: () => import("/html/pages/organizations/index.vue")
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: indexsg10wg2y74Meta || {},
    component: () => import("/html/pages/password-reset/index.vue")
  },
  {
    name: "password-reset-new-password",
    path: "/password-reset/new-password",
    meta: new_45passwordxdqC5Bel35Meta || {},
    component: () => import("/html/pages/password-reset/new-password.vue")
  },
  {
    name: "password-reset-sent",
    path: "/password-reset/sent",
    meta: sentgRPtWvLRA4Meta || {},
    component: () => import("/html/pages/password-reset/sent.vue")
  },
  {
    name: "password-reset-success",
    path: "/password-reset/success",
    meta: successqH7Q5P3OfsMeta || {},
    component: () => import("/html/pages/password-reset/success.vue")
  },
  {
    name: "rank-settings-benefits-id",
    path: "/rank-settings/benefits/:id()",
    meta: _91id_93OEWjCPWkFSMeta || {},
    component: () => import("/html/pages/rank-settings/benefits/[id].vue")
  },
  {
    name: "rank-settings-benefits-new",
    path: "/rank-settings/benefits/new",
    meta: new7WfF1OSycGMeta || {},
    component: () => import("/html/pages/rank-settings/benefits/new.vue")
  },
  {
    name: "rank-settings",
    path: "/rank-settings",
    meta: indexaFLY1tlA56Meta || {},
    component: () => import("/html/pages/rank-settings/index.vue")
  },
  {
    name: "rank-settings-ranks-id",
    path: "/rank-settings/ranks/:id()",
    meta: _91id_93X6OwJp2fcBMeta || {},
    component: () => import("/html/pages/rank-settings/ranks/[id].vue")
  },
  {
    name: "rank-settings-ranks-new",
    path: "/rank-settings/ranks/new",
    meta: new60JwiJLsDAMeta || {},
    component: () => import("/html/pages/rank-settings/ranks/new.vue")
  },
  {
    name: "report-points",
    path: "/report-points",
    meta: indexFptdu5oDqOMeta || {},
    component: () => import("/html/pages/report-points/index.vue")
  },
  {
    name: "report-receipts",
    path: "/report-receipts",
    component: () => import("/html/pages/report-receipts/index.vue")
  },
  {
    name: "report-reservations",
    path: "/report-reservations",
    component: () => import("/html/pages/report-reservations/index.vue")
  },
  {
    name: "report-rfm",
    path: "/report-rfm",
    meta: index2PWO7oaeAJMeta || {},
    component: () => import("/html/pages/report-rfm/index.vue")
  },
  {
    name: "reservations-id",
    path: "/reservations/:id()",
    component: () => import("/html/pages/reservations/[id].vue")
  },
  {
    name: "reservations",
    path: "/reservations",
    component: () => import("/html/pages/reservations/index.vue")
  },
  {
    name: "shops-id",
    path: "/shops/:id()",
    component: () => import("/html/pages/shops/[id].vue")
  },
  {
    name: "shops",
    path: "/shops",
    component: () => import("/html/pages/shops/index.vue")
  },
  {
    name: "shops-new",
    path: "/shops/new",
    component: () => import("/html/pages/shops/new.vue")
  },
  {
    name: "signin",
    path: "/signin",
    meta: signinZLX4IYXrMZMeta || {},
    component: () => import("/html/pages/signin.vue")
  },
  {
    name: "statistics-page-views",
    path: "/statistics/page-views",
    component: () => import("/html/pages/statistics/page-views.vue")
  },
  {
    name: "statistics-reservation-views",
    path: "/statistics/reservation-views",
    component: () => import("/html/pages/statistics/reservation-views.vue")
  },
  {
    name: "user-segments-id",
    path: "/user-segments/:id()",
    meta: _91id_931KOy5qdgFJMeta || {},
    component: () => import("/html/pages/user-segments/[id].vue")
  },
  {
    name: "user-segments",
    path: "/user-segments",
    meta: indexMhvLUOhSIZMeta || {},
    component: () => import("/html/pages/user-segments/index.vue")
  },
  {
    name: "user-segments-new",
    path: "/user-segments/new",
    meta: newJacvNIYou5Meta || {},
    component: () => import("/html/pages/user-segments/new.vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    component: () => import("/html/pages/users/[id].vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/html/pages/users/index.vue")
  }
]