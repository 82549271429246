export const colors = {
  primary: '#3587dc',
  'primary-50': '#EBF3FC',
  'primary-100': '#8ab9eb',
  warning: '#f8c076',
  success: '#4bd08b',
  error: '#e6273e',
  info: '#46caeb',
  background: '#FFFFFF',
  surface: '#FFFFFF',
  sidebar: '#43495C',
  'dark-10': '#fafafb',
  'dark-20': '#f6f6f7',
  'dark-30': '#ecedee',
  'dark-40': '#e0e1e4',
  'dark-50': '#c4c6cb',
  'dark-100': '#80848e',
  'dark-500': '#49505d',
  'dark-900': '#131c2d',
};

export const componentDefaults = {
  VSelect: {
    density: 'compact',
  },
  VTextField: {
    density: 'compact',
  },
  VTextarea: {
    density: 'compact',
  },
  VAutocomplete: {
    density: 'compact',
  },
  VCombobox: {
    density: 'compact',
  },
  VRow: {
    noGutters: true,
  },
};
