<template>
  <v-navigation-drawer
    v-model="setDrawer"
    floating
    :mobile-breakpoint="1300"
    width="270"
    location="start"
    class="bg-sidebar admin-nav"
  >
    <div class="my-6 text-center">
      <slot name="logo" />
    </div>

    <v-list class="mx-5 py-4 pt-0">
      <div
        v-for="(item, index) in items"
        :key="index"
      >
        <v-list-subheader
          v-if="item.header && item.displayable"
          class="admin-nav__group-header text-white text-body-2"
        >
          {{ item.header }}
        </v-list-subheader>

        <v-list-group v-if="item.displayable && item.lists">
          <template v-slot:activator="{ props }">
            <sidebar-item
              v-bind="props"
              :icon="item.icon"
              :title="item.title"
            />
          </template>

          <sidebar-item
            v-for="page in filteredItems(item.lists)"
            :title="page.title"
            :to="page.to"
            :active="isActive(page.to)"
          />
        </v-list-group>

        <sidebar-item
          v-else-if="item.displayable && item.title"
          :title="item.title"
          :to="item.to"
          :active="isActive(item.to)"
          :icon="item.icon"
        />
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'AdminNavigationDrawer',
  props: {
    drawer: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      title: 'Merci Admin',
      mini: false,
    };
  },

  computed: {
    setDrawer: {
      get() {
        return this.drawer;
      },
      set(val) {
        return this.$emit('update:drawer', val);
      },
    },
  },

  methods: {
    goHome() {
      this.$router.push('/dashboard');
    },

    filteredItems(items) {
      if (items) {
        return items.filter(item => item.displayable);
      }

      return [];
    },

    isActive(path) {
      return this.$route.path.startsWith(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-nav {
  &__group-header {
    padding-inline-start: 0 !important;
  }

  :deep(.v-navigation-drawer__content) {
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

a {
  text-decoration: none;
}
</style>
