<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/store/auth';

definePageMeta({
  layout: 'pre-login',
});

const { login, refreshProfile } = useAuthStore();
const { authenticated } = storeToRefs(useAuthStore());

const operator = ref({
  email: '',
  password: '',
});

const formValid = ref(false);
const loading = ref(false);

const signIn = async () => {
  if (!formValid.value || loading.value) {
    return;
  }

  loading.value = true;

  try {
    if (await login(operator.value)) {
      await refreshProfile();
      if (authenticated.value) {
        // Go to the previous page
        await navigateTo('/dashboard');
      }
    }
  } catch (e) {
    loading.value = false;
  }
};
</script>

<template>
  <pre-login-page>
    <template #title>
      <img
        src="/images/logo.png"
        :width="70"
        alt="Logo"
        class="logo"
      />
      <br />
      Fanship Platformにログイン
    </template>

    <v-form
      ref="form"
      v-model="formValid"
      @submit.prevent
    >
      <m-text-field
        label="メールアドレス"
        v-model="operator.email"
        :rules="[$rules.email, $rules.required]"
      />

      <m-text-field
        v-model="operator.password"
        label="パスワード"
        :rules="[$rules.password, $rules.required]"
        type="password"
        class="mt-4"
      />

      <m-btn
        class="mt-4"
        block
        color="primary"
        type="submit"
        :loading="loading"
        @click="signIn"
      >
        ログイン
      </m-btn>
    </v-form>

    <div class="w-100 text-center mt-9">
      <span>
        パスワードを忘れましたか？
        <nuxt-link
          to="/password-reset"
          class="ms-2 grey--text text--darken-4 font-600"
        >
          リセット
        </nuxt-link>
      </span>
    </div>
  </pre-login-page>
</template>
